/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "~components/Layout";
import SEO from "~components/SEO";

import { AppContext } from "~context/AppContext";

class ContactPageComponent extends Component {
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}

  //

  render() {
    return (
      <>
        <SEO frontmatterTitle={this.props.frontmatter.title} />
        <Layout className="contact-page relative text-dark-grey">
          <section className="index-page__intro grid relative w-full h-full">
            <h1
              className="grid-end--8 grid-end--xs-12 f1 font-bold mb-8 animation-appear"
              style={{ animationDelay: `100ms` }}
              dangerouslySetInnerHTML={{
                __html: this.props.frontmatter.text
              }}
            ></h1>
            <Img
              fluid={this.props.frontmatter.image.childImageSharp.fluid}
              style={{ animationDelay: `200ms` }}
              className="grid-end--3 grid-start--10 grid-end--xs-6 grid-start--xs-4 py-8 animation-appear"
              imgStyle={{
                objectFit: `contain`
              }}
              // loading="eager"
            />
            <h3
              className="grid-end--8 f3 animation-appear pb-32"
              style={{ animationDelay: `300ms` }}
            >
              Then get in touch:
              <br />
              <a
                className="underline"
                href="mailto:joseph@jbphotohouse.com.au?subject=General Enquiry"
              >
                joseph@jbphotohouse.com.au
              </a>
              .
            </h3>
          </section>
        </Layout>
      </>
    );
  }
}

const ContactPage = ({ data }) => {
  const { siteMetadata: metadata } = data.site;
  const { frontmatter } = data.markdownRemark;

  return (
    <AppContext.Consumer>
      {appContext => (
        <ContactPageComponent
          appContext={appContext}
          frontmatter={frontmatter}
          metadata={metadata}
        />
      )}
    </AppContext.Consumer>
  );
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        keywords
        text
        image {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        keywords
        author
        url
        twitterUsername
      }
    }
  }
`;
